import { useState } from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Navbar } from '../navbar';
import { Box } from '@mui/material';
import { Safari15Warning } from '../warnings/safari-15';

interface LoggedInLayoutProps {
  children?: ReactNode;
}

const LoggedInLayoutRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  justifyContent: 'center',
  paddingTop: 64,
}));

export const LoggedInLayout: FC<LoggedInLayoutProps> = props => {
  const { children } = props;
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);

  return (
    <>
      <LoggedInLayoutRoot>
        <Box
          sx={{
            display: 'flex',
            flex: '1 1 auto',
            flexDirection: 'column',
            maxWidth: 'md',
          }}
        >
          <Safari15Warning />
          {children}
        </Box>
      </LoggedInLayoutRoot>
      <Navbar onOpenSidebar={(): void => setIsSidebarOpen(true)} />
    </>
  );
};

LoggedInLayout.propTypes = {
  children: PropTypes.node,
};
