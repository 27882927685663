import { Box, Container, Divider, Skeleton, Typography } from '@mui/material';
import React from 'react';

const ClaimsDashboardSkeleton = () => {
  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
        }}
      >
        <Container maxWidth={'xl'} sx={{ my: 10 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h4">
              <Skeleton width={130} />
            </Typography>
            <Skeleton
              variant="rectangular"
              width={150}
              height={40}
              sx={{ borderRadius: 1 }}
            />
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Skeleton
              variant="text"
              width={'100%'}
              sx={{ fontSize: '10rem' }}
            />
          </Box>
          <Divider />
          <Skeleton
            variant="rectangular"
            width={200}
            height={30}
            sx={{ borderRadius: 1, my: 2 }}
          />
          <Divider />

          <Skeleton variant="text" sx={{ fontSize: '2rem', my: 2 }} />
          {Array.from({ length: 3 }, (el, index) => (
            <Skeleton
              key={index}
              variant="rectangular"
              height={150}
              sx={{ my: 2, borderRadius: 1 }}
            />
          ))}
        </Container>
      </Box>
    </>
  );
};

export default ClaimsDashboardSkeleton;
