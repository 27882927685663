/**
 * create an mui warning for safari 15 that there are compatibility issues with safari 15
 * should render message with the mui alert component
 */

import { Alert, AlertTitle } from '@mui/material';
import {
  isSafari,
  browserName,
  browserVersion,
  fullBrowserVersion,
} from 'react-device-detect';

/**
 * Show a warning if the user is using Safari 15
 */
export const Safari15Warning: React.FC = () => {
  if (isSafari && browserVersion === '15') {
    return (
      <Alert severity="warning" variant="filled" sx={{ mt: 5 }}>
        Due to compatibility issues with <strong>Safari 15</strong>, we
        recommend using a different browser to access Brief. Your browser is{' '}
        <strong>
          {browserName} {fullBrowserVersion}
        </strong>
      </Alert>
    );
  } else {
    return null;
  }
};
