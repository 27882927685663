import { useEffect } from 'react';
import type { NextPage } from 'next';
import Head from 'next/head';
import { Divider } from '@mui/material';
import { MainLayout } from '../components/main-layout';
import { HomeClients } from '../components/home/home-clients';
import { HomeHero } from '../components/home/home-hero';
import { HomeDevelopers } from '../components/home/home-developers';
import { HomeDesigners } from '../components/home/home-designers';
import { HomeFeatures } from '../components/home/home-features';
import { HomeTestimonials } from '../components/home/home-testimonials';
import { gtm } from '../lib/gtm';
import { useRouter } from 'next/router';
import { AuthGuard } from 'src/components/authentication/auth-guard';
import { LoggedInLayout } from 'src/components/layouts/logged-in-layout';
import ClaimsDashboardSkeleton from 'src/components/skeletons/claims/claims-dashboard-skeleton';

const Home: NextPage = () => {
  //Gtm events are now handled by analytics.js in lib/analytics -> https://getanalytics.io/plugins/google-tag-manager/
  // useEffect(() => {
  //   gtm.push({ event: 'page_view' });
  // }, []);
  const router = useRouter();
  router.push('/claims')

  return <ClaimsDashboardSkeleton />;

  // return (
  //   <>
  //     <Head>
  //       <title>Brief</title>
  //     </Head>
  //     <main>
  //       <HomeHero />
  //       <Divider />
  //       {/* <HomeDevelopers />
  //       <Divider />
  //       <HomeDesigners />
  //       <HomeTestimonials />
  //       <HomeFeatures />
  //       <Divider />
  //       <HomeClients /> */}
  //     </main>
  //   </>
  // );
};

Home.getLayout = page => (
  <AuthGuard>
    <LoggedInLayout>{page}</LoggedInLayout>
  </AuthGuard>
);

export default Home;
